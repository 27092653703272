import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Container from '../components/Container'
import Link from '../components/Link'

import colors from '../assets/js/colors'
import logo from '../assets/img/logo.png'

const Header = styled.h1`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`

const Logo = styled.img`
  width: 48px;
  margin-right: .5rem;
`

const Item = styled.div`
  color: ${colors.text};
  margin-bottom: 2rem;
`

const List = ({ title, list }) =>
  <>
    <h2>{title}</h2>
    {list.map(({ node }) =>
      node.fields ? ( // from markdown
        <Item key={node.fields.slug}>
          <h4><Link underline to={node.fields.slug}>{get(node, 'frontmatter.title') || node.fields.slug}</Link></h4>
          <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </Item>
      ) : ( // all site pages
        <Item key={node.path}>
          <Link underline to={node.path}>{node.path}</Link>
        </Item>
      )
    )}
  </>

const Index = ({ data }) => {
  const title = get(data, 'site.siteMetadata.title')
  const pages = get(data, 'allMarkdownRemark.edges')
  const examples = filter(pages, page => page.node.fields.slug.includes('/examples/'))
  const clients = filter(pages, page => page.node.fields.slug.includes('/clients/'))
  const allSitePages = sortBy(get(data, 'allSitePage.edges'), 'node.path')

  return (
    <Container>

      <Helmet>
        <title>{title}</title>
        <meta property='description' content='Choose a Dot Dot Data Page to broadcast on your data device' />
        <link rel='shortcut icon' href='favicon.ico' />
      </Helmet>

      <Header><Logo src={logo} alt='' /><span>Dot Dot Data Pages</span></Header>

      <List title='Examples' list={examples} />
      <List title='Clients' list={clients} />
      <List title='All Site Pages' list={allSitePages} />

    </Container>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
